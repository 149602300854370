@import '../../../constants.scss';

.k8student {
    .image {
        flex: 0 0 40%;
        margin:0 24px;
        img {
            width: 100%;
        }
    }
    &-desc {
        @include center(24px);
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        text-align: start;
        color: $DARKGREY;
        align-items: flex-start;
        padding:0 12px;
        strong {
            color: black;
        }
    }
    h5 {
        font-weight: 400;
        margin:24px 0;
    }
}

@media only screen and (max-width: 767px) {
    .k8student {
        &-desc, &-links{
            max-width: 420px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}