@import '../../constants.scss';

.about {
    &-card {
        @include center();
        &-box {
            display: flex;
            margin: 24px 0;
            justify-content: space-between;
            align-items: stretch;
            &-image {
                padding: 0 48px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            &-text {
                padding: 0 48px;
                &-title {
                    font-size: 1.8rem;
                    margin: 0 0 24px 0;
                }
                &-content {
                    color: $DARKGREY;
                }
            }
            text-align: start;
        }
        .tall-card .about-card-box-image {
            flex: 0 0 40%;
        }
        .wide-card .about-card-box-image{
            flex: 0 0 50%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .about-card-box {
        flex-direction: column;
        align-items: center;
        .about-card-box-image {
            flex: 0 0 100%;
        }
    }
}