@import '../../constants.scss';

.navigator {
  
    .bread-crumbs {
        display: block;
        font-weight: bold;
        a {
            color: $DARKGREY;
        }
    }
    & .menu-item a{
        color: black;
    }
    .fixedMenu {
        display: none;
        position: fixed;
        z-index: 100;
        top: 10px;
        right: 10px;
        background-color: rgba(250,250,250,0.9);
    }
}
#simple-popper {
    top: -10px !important;
}
a {
  .MuiListItem-root{
    font-weight: 400;
    color: $DARKGREY !important;
  }
}
@media only screen and (max-width: 767px) {
    .navigator {
        .fixedMenu {
            display: block;
        }
        .MuiBreadcrumbs-ol {
            flex-direction: column;
        }
        .bread-crumbs {
            display: none;
        }
    }
}