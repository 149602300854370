@import '../../constants.scss';

.driving-test {
  @include center(20px);
  width: 80%;
  flex-grow: 1;
  .Mui-selected {
    background-color: $PRIMARYGREEN !important;
  }
  .handbook-wrapper{
    .MuiButton-containedPrimary {
      background-color: $PRIMARYGREEN !important;
    }
    button{
      margin: 20px;
    }
  }
  .questions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .MuiPagination-ul {
      justify-content: space-around;
    }
    .question-box {
      text-align: start;
      width: 100%;
      padding: 20px;
      margin: 20px 0;
      span {
        color: inherit;
      }
      .green {
        background-color: green;
        color: white;
        font-weight: bold;
      }
      .red {
        background-color:red;
        color: white;
        font-weight: bold;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .driving-test {
    width: 100%;
  }
}
