@import '../../constants.scss';

@media all and (min-width: 480px) {
    .Register {
      padding: 60px 0;
    }
  
    .Register form {
      margin: 0 auto;
      max-width: 320px;
    }
}

.Register {
    .MuiButtonBase-root{
        background-color: $PRIMARYGREEN;
        color: $WHITE;
    }
    .already-registered,
    .already-registered a {
      text-align: right;
      font-size: 13px;
      padding-top: 10px;
      color: #7f7d7d;
      margin: 0;
    }
    .already-registered a {
        color: $PRIMARYGREEN;
    }
}
