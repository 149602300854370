$BORDER:    #e9e9e9;
$LIGHTGREY: #bababa;
$DARKGREY:  #7f7f7f;
$FOCUSBLACK:#555555;
$WHITE:     #ffffff;
$BLUE:      #337ab7;
$PRIMARYGREEN:   #72d5cd;

@mixin center($verticalMargin: 0) {
    margin: $verticalMargin auto;
    max-width: 1140px;
    padding:0 12px;
}

@mixin padding($vertical:0, $horizonal: 12vw) {
    padding: $vertical $horizonal;
}

