@import '../../constants.scss';

.collapse-card {
    text-align: start;
    margin-bottom: 12px;
    color: $DARKGREY;
    &-title {
        width: 100%;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        &-icon {
            font-weight: bolder;
            background-color: #f3f3f3;
            padding: 9px 12px;
            margin-right: 12px;
        }
        .green {
            background-color: $PRIMARYGREEN;
            color: $WHITE;
        }
    }
    padding-bottom: 12px;
    border-bottom: 1px solid $LIGHTGREY;
    &-desc {
        padding-left: 58px;
        font-size: 1rem;
        max-height: 0px;
        overflow: hidden;
        transition: all .5s; 
    }
    .open {
        max-height: 550px;
    }
}