@import '../../constants.scss';

.programs {
    &-box {
        @include center(24px);
        color: $DARKGREY;
        font-size: 1.5rem;
        text-align: start;
        &-row {
            display: flex;
            justify-content: space-around;
            & > a {
                margin: 24px;
                width: 351px;
                height: 300px;
                overflow: hidden;
            }
            img {
                width: 100%;
                max-width: initial;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .programs-box-row{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}