@import '../../constants.scss';
.app {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  &-header {
    border-bottom: 1px solid $BORDER;
    &-wrapper {
        @include center();
        padding: 12px 0;
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        color: $LIGHTGREY;
        .MuiButtonBase-root {
            color: $DARKGREY;
        }
        .icon {
            margin: 0 12px;
        }
        a .icon {
            margin: 0 auto;
        }
        & > div {
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }
    }
  }
  &-navigator {
    @include center(12px);
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    & > img {
      max-width: 250px;
      height: auto;
    }
  }
  &-footer {
      display: flex;
      background: black;
      color: white;
      @include padding(12px);
      justify-content: space-between;
      font-family: "Montserrat";
  }
}

@media only screen and (max-width: 767px) {
  .app {
    height: auto;
    .app-header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .app-navigator {
        display: block;
    }
  }
}
