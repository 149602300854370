@import '../../constants.scss';

.sub-header {
    padding-top: 240px;
    padding-bottom: 40px;
    background-position: center 0px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    .bg-img {
        position:absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        min-width: 100%;
        max-width: initial;
        min-height: 100%;
        z-index: -1;
    }
    
    &-title {
        @include center();
        font-weight: bold;
        color: $WHITE;
        font-size: 2.8rem;
        letter-spacing: 2px;
    }
    &-content {
        @include center();
        color: $DARKGREY;
        color: $WHITE;
        font-size: 1.6rem;
    }
}