@import '../../../constants.scss';

.istudent {
    .image {
        flex: 0 0 50%;
        padding: 0 48px;
        img {
            width: 100%;
        }
    }
    &-desc {
        color: $DARKGREY;
        text-align: start;
        & > div:first-child{
            margin: 0 48px;
        }
    }
    &-desc, &-links {
        @include center(24px);
        display: flex;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 767px) {
    .istudent {
        &-desc, &-links {
            max-width: 420px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .image {
                margin: 24px 0;
            }
        }
    }
}