@import '../../../constants.scss';

.videos {
  border-top: 1px solid $BORDER;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  flex-grow: 1;
  &-drawer {
    .expand-drawer {
      display: none;
    }
    .list-drawer {
      display: initial;
    }
    .MuiListItemIcon-root {
      min-width: 36px !important;
    }
    overflow: overlay;
    background-color: $BORDER;
    min-width: 200px;
    width: 20%;
    max-width: 300px;
    word-break: break-word;
    ul {
      overflow: scroll;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow: scroll;
    &-player {
      width: 90%;
      max-width: 940px;
      padding: 20px 0;
      .video-react-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &-title {
      font-weight: 600;
      margin-top: 20px;
    }
    &-comments {
      color: $LIGHTGREY;
    }
  }
}

@media all and (max-width: 580px) {
  .videos {
    display: block;
    overflow: visible;
    &-drawer {
      overflow: auto;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
      margin-bottom: 10px;
      .expand-drawer {
        display: initial;
        p {
          margin: 0;
        }
        .MuiExpansionPanelDetails-root {
          padding: 0;
        }
        ul {
          overflow: auto;
          width: 100%;
          padding: 0;
        }
      }
      .list-drawer {
        display: none;
      }
      width: 100%;
      max-width: initial;
    }
    &-content {
      &-player {
        width: 100%;
        padding:0;
      }
    }
  }
}
