@import '../../constants.scss';

.home {
    &-program {
        @include center(20px);
        display: flex;
        justify-content: space-around;
        align-items:stretch;
        &-box {
            max-width: 360px;
            flex:1;
            padding-bottom: 72px;
            position: relative;
            &-icon {
                color: $DARKGREY;
                .MuiSvgIcon-root {
                    height: 70%;
                    width: 70%;
                    transform: scale(0.5);
                }
            }
            &-title {
                font-weight: bold;
                font-size: 1rem;
            }
            &-content {
                font-size: 1rem;
                color: $DARKGREY;
                margin: 20px;
            }
            &-btn {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                .MuiButtonBase-root {
                    background-color: $PRIMARYGREEN;
                    color: $WHITE;
                }
            }
        }
    }
    &-footer {
        background-color: $PRIMARYGREEN;
        &-wrapper {
            margin: 0 auto;
            padding: 48px 0;
            width: 55%;
        }
        &-title {
            letter-spacing: 2px;
            font-weight: bold;
            font-size: 1.8rem;
            margin: 12px 0;
        }
        &-content {
            color: $DARKGREY;
            font-size: 1.5rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .home-program {
        flex-direction: column;
        align-items: center;
    }
    .home-carousel {
        display: none;
    }
}