@import './constants.scss';

body {
  margin: 0;
  font-size: 100%;
  font-family: 'Hiragino Sans GB', "Raleway", "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box !important;
  }
  img {
      max-width: 100vw;
  }
  a {
      color: $PRIMARYGREEN;
      &:hover {
          text-decoration: none;
          color: $PRIMARYGREEN;
      }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
