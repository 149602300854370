@import '../../../../constants.scss';

.icollege {
    .image {
        flex: 0 0 55%;
        margin:0 24px;
        img {
            width: 100%;
        }
    }
    &-button {
        text-align: center;
        margin-bottom: 24px;
    }
    &-desc {
        color: $DARKGREY;
        align-items: center;
        padding:0 12px;
        strong {
            color: black;
        }
    }
    &-desc, &-packages {
        @include center(24px);
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        text-align: start;
    }
    &-packages > div {
        flex: 0 0 48%;
        h5 {
            font-weight: 600;
            margin: 24px 0;
        }
    }
    .MuiButtonBase-root{
        background-color: $PRIMARYGREEN;
        color: $WHITE;
    }
    a:hover {
        text-decoration: none;
    }
}

@media only screen and (max-width: 767px) {
    .icollege {
        &-desc, &-links, &-packages, h5{
            max-width: 420px !important;
            display: flex;
            flex-direction: column;
            align-items: stretch !important;
            .image {
                margin: 0 !important;
            }
        }
    }
}