@import '../../../constants.scss';

.pcollege {
    .image {
        flex: 0 0 40%;
        margin:0 24px;
        img {
            width: 100%;
        }
    }
    &-desc {
        color: $DARKGREY;
        align-items: center;
        padding:0 12px;
        strong {
            color: black;
        }
    }
    &-desc, &-packages {
        @include center(24px);
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        text-align: start;
    }
    &-packages > div {
        flex: 0 0 48%;
    }
    h5 {
        @include center(48px);
        font-weight: 600;
        text-align: start;
    }
}

@media only screen and (max-width: 767px) {
    .pcollege {
        &-desc, &-links, &-packages, h5{
            max-width: 420px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}