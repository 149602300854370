@import '../../constants.scss';

.contact {
    &-box {
        @include center(24px);
        display: flex;
        align-items: stretch;
        &-content {
            text-align: start;
            flex: 3;
            margin: 0 24px;
        }
        &-info {
            text-align: start;
            flex: 2;
            margin: 0 24px;
            color: $DARKGREY;
            &-title {
                color: black;
                font-weight: bold;
                margin: 24px 0;
            }
            &-address, &-phone, &-email, &-time{
                margin: 12px 0;
            }
        }
        .MuiButtonBase-root{
            background-color: $PRIMARYGREEN;
            color: $WHITE;
        }
    }
}

@media only screen and (max-width: 767px) {
    .contact-box {
        display: flex;
        flex-direction: column;
    }
}