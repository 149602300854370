@import '../../../../constants.scss';

.ihighschool {
    .image {
        flex: 0 0 60%;
        margin-right: 24px;
        img {
            width: 100%;
        }
    }
    &-button {
        margin-bottom: 24px;
        text-align: center;
    }
    &-desc {
        color: $DARKGREY;
        align-items: center;
        strong {
            color: black;
        }
    }
    h5 {
        @include center(48px);
        text-align: start;
    }
    &-desc, &-packages {
        @include center(24px);
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        text-align: start;
    }
    &-packages > div {
        flex: 0 0 48%;
        h5 {
            font-weight: 600;
            margin: 24px 0;
        }
    }
    .MuiButtonBase-root{
        background-color: $PRIMARYGREEN;
        color: $WHITE;
    }
    a:hover {
        text-decoration: none;
    }
}

@media only screen and (max-width: 767px) {
    .ihighschool {
        &-desc, &-links, &-packages, h5{
            max-width: 420px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}