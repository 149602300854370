@import '../../../../constants.scss';

.iapplication {
    &-desc {
        @include center(24px);
        text-align: start;
    }
    input {
        margin: 9.5px 0;
    }
    &-form {
        @include center(24px);
        .card-title {
            text-align: start;
            color: $DARKGREY;
        }
    }
    button{
        margin:16px;
        background-color: $PRIMARYGREEN;
        color: $WHITE;
    }
    .MuiFormControl-root {
        width: 100%;
    }
    .MuiFormLabel-root {
        text-align: start;
    }
    .MuiCard-root {
        margin: 12px 0;
    }
    .row {
        width: 100%;
    }
    .col {
        margin: 12px 0;
        display: flex;
        align-items: center;
	min-width:280px;
    }
}

@media only screen and (max-width: 767px) {
    .iapplication {
        &-form {
            .row {
            }
            max-width: 420px;
        }
        &-desc {
            max-width: 420px;
        }
    }
}
