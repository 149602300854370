@import '../../constants.scss';

@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
}

.Login {
    .MuiButtonBase-root{
        background-color: $PRIMARYGREEN;
        color: $WHITE;
    }
    .forgot-password,
    .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
    }

    .forgot-password a {
    color: $PRIMARYGREEN;
    }
}